<template>
    <validation-observer tag="div" ref="observer" v-if="!loading">
        <b-card :header="$t('settings.dhl.group.common')">
            <b-card-text>
                <b-row>
                    <b-col cols="4">
                        <zw-switch-group v-model="form.customs_active"
                                         name="customs_active"
                                         :label-prefix="labelPrefix"
                        ></zw-switch-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="4">
                        <b-form-group :label="$t(labelPrefix+'customs_countries')">
                            <multiselect v-model="form.customs_countries"
                                         name="customs_countries"
                                         :searchable="true"
                                         :options="getCountries().map(country=>country.iso2)"
                                         :multiple="true"
                                         select-label=""
                                         :custom-label="opt => getCountries().filter(country=>{
                                             return country.iso2==opt
                                         })[0].name"
                            ></multiselect>
                        </b-form-group>
                    </b-col>

                    <b-col cols="4">
                        <zw-select-group v-model="form.export_type"
                                         name="export_type"
                                         :label-prefix="labelPrefix"
                                         :options="['OTHER','PRESENT','COMMERCIAL_SAMPLE','DOCUMENT','RETURN_OF_GOODS','COMMERCIAL_GOODS']"
                        ></zw-select-group>
                    </b-col>

                    <b-col cols="4">
                        <zw-input-group v-model="form.customs_tariff_number"
                                        name="customs_tariff_number"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>
                </b-row>
            </b-card-text>
        </b-card>

        <b-row>
            <b-col sm="12" class="text-xs-center mt-2">
                <b-row align-h="end">
                    <b-col sm="6" class="text-sm-right">
                        <b-button block @click="onSubmit()" variant="primary">
                            {{ $t('common.button.save') }}
                        </b-button>
                    </b-col>
                </b-row>
            </b-col>

        </b-row>
    </validation-observer>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    name: 'SettingsDhlCustoms',
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            form: {},
            labelPrefix: 'settings.dhl.label.'
        }
    },
    methods: {
        ...mapGetters('CommonData', ['getCountries']),
        ...mapGetters('Settings', ['getDhl']),
        shown() {
            this.loading = true
            const settingsDhl = this.$store.dispatch('Settings/fetchDhl')

            Promise.all([settingsDhl]).then(() => {
                this.form = this.getDhl()

                this.loading = false
            });
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.$store.dispatch('Settings/save', this.form).then((response) => {
                        this.afterSave(response)
                    }).catch(errors => {
                        this.$refs['observer'].setErrors(errors)
                        this.showValidationError()
                    })
                } else {
                    this.showValidationError()
                }
            })
        },
        afterSave(response) {
            if (response.status == 200 || response.status == 201) {
                this.$root.showMessage(
                    this.$t('common.form.title.success'),
                    this.$t('common.toasts.success_updated'),
                    'success'
                )

                this.shown()
            }
            this.$root.afterSettingsUpdate()
        },
    },
    mounted() {
        this.shown()
    }
}
</script>